.founders-main-div {
  .content-div {
    flex-direction: column;
    color: #ffffff;

    h1 {
      text-align: center;
      font-size: 36px;

      font-weight: 700;

      @media (max-width: 500px) {
        font-size: 23px;
      }
    }

    h6 {
      text-align: center;
      width: 650px;
      font-size: 17px;
      line-height: 30px;
      font-weight: 400;

      @media (max-width: 500px) {
        width: 350px;
        line-height: 25px;
        margin-top: 10px;
      }
    }
  }
  .founder-div {
    img {
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
    }
    .card {
      background-color: #66380f;
      border-radius: 10px !important;
      @media (max-width: 1000px) {
        margin-top: 15px;
      }
    }
    .card-div {
      color: #ffffff;

      .icon-div {
        .icon {
          height: 25px;
          width: 25px;
          background-color: #c96a1b;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 5px;
        }
      }
      h6 {
        color: #f37d1a;
      }
    }
  }
}
