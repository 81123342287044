.join-customer-main-div {
  margin: 0 50px;
  background: #66380f;
  color: #ffffff;
  border: 1px solid #a56126;
  display: flex;
  border-radius: 15px;
  align-items: center;
  text-align: center;
  flex-direction: column;
  // margin-top: -70px;
  // z-index: 99999;
  // position: relative;
  @media (max-width: 1000px) {
    margin: 0 10px!important;
  }

  h3 {
    font-size: 40px;
    font-weight: 600;
    @media (max-width:500px) {
    font-size: 30px;
    }
  }

  .image-div {
    .image {
      height: 40px;
      width: 14%;
      @media (max-width:500px) {
        height: 34px;
        width: 23%;
        margin: 15px 16px !important;
      }
    }
  }

  // .part {
  //   background-color: transparent;
  //   width: 40%;

  //   @media (max-width: 450px) {
  //     width: 70%;
  //   }

  //   h1 {
  //     background-color: transparent;
  //     color: #ffffff;
  //     font-size: 60px;
  //     line-height: 90px;

  //     @media (max-width: 850px) {
  //       font-size: 33px;
  //       line-height: 55px;
  //     }

  //     @media (max-width: 450px) {
  //       font-size: 22px;
  //     }
  //   }
  // }

  //   Button {
  //     background-color: #000000;
  //     margin: 10px 0 30px 0;
  //     color: #ffffff;
  //     padding: 15px 60px;
  //     border-radius: 40px;
  //     font-size: 20px;

  //     &:hover {
  //       background-color: #000000;
  //       color: #ffffff;
  //     }
  //   }
}
