.solutions-main-div{
    .second-div {
      .row-div{
        @media (max-width:1000px) {
          margin: 0 15px !important;
        }
        .content-div {
          margin-top: 60px;
          color: #ffffff;
          @media (max-width:1000px) {
            margin-top: 0;
            margin: 3px 0 !important;
          }
          h6 {
            font-size: 14px;
            color: #f37d1a;
            @media (max-width:500px) {
              text-align: center;
              
            }
          }
          h1 {
            font-size: 39px;
            padding-right: 50px;
            @media (max-width:500px) {
              font-size: 30px;
              text-align: center;
              padding-right: 0;
              
            }
          }
          h5 {
            font-weight: 400;
            line-height: 25px;
            font-size: 17.5px;
            padding-right: 50px;
            @media (max-width:500px) {
              text-align: center;
              padding-right: 0;
            }
          }
          .row {
            .buttons-div{
              @media (max-width:500px) {
                flex-direction: column;
              }
            .buy-button {
              height: 55px;
              width: 185px;
              background-color: #f37d1a;
              color: #ffffff;
              font-size: 19px;
              @media (max-width:500px) {
           width: 100%;
              }
            }
            .sell-button {
              color: #ffffff;
              height: 55px;
              width: 190px;
              border: 2px solid #ffffff;
              margin-left: 13px;
              @media (max-width:500px) {
                width: 100%;
                margin-left: 0;
                margin-top: 15px;
                   }
              .icon {
                margin-left: 15px;
                height: 20px;
                width: 20px;
              }
            }
          }
          }
        }
        .image-div{
            img{
                width: 100%;
                height:450px;
                border-radius: 15px;
                @media (max-width:1000px) {
                  height: unset;
                }
            }
        }
      }
    }
}