.system-main-div {
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;

  .first-div {
    h3 {
      color: #f37d1a;
      font-size: 20px;

      @media (max-width:500px) {
        font-size: 17px;
      }
    }

    h1 {
      width: 600px;

      @media (max-width:900px) {
        width: 700px;
      }

      @media (max-width:500px) {
        width: 300px;
      }
    }
  }

  .second-div {
    .card-row {
      @media (max-width:1000px) {
        margin-top: 0 !important;

      }

      .bottom-col {
        @media (max-width:1000px) {
          padding-top: 3rem !important;

        }
      }

      .first-card {
        background-color: #66380f;
        border: none;
        border-radius: 15px;
      }

      .second-card {
        background-color: #f37d1a;
        border: none;
        border-radius: 15px;
      }

      .first {
        justify-content: center;
        align-items: center;
        text-align: center;

        .icon-div {
          height: 60px;
          width: 60px;
          background-color: #e37c26;
          border-radius: 50%;

          .i-1 {
            height: 30px;
            width: 30px;
          }
        }

        .content {
          .title {
            font-size: 20px;
          }

          .description {
            font-size: 14px;
            line-height: 20px;
            color: #A5BCD6;
          }
        }

        h3 {
          color: #f37d1a;
          font-size: 17px;
          font-weight: 600;
        }

        h1 {
          width: 45%;
          line-height: 55px;
          font-size: 39px;

          @media (max-width: 600px) {
            width: 90%;
            font-size: 30px;
          }
        }
      }
    }
  }
}