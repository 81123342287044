.works-main-div {
  padding-top: 30px;
  color: #ffffff;
  display: flex;
  flex-direction: column;

  .first-div {
    justify-content: center;
    align-items: center;
    text-align: center;

    h3 {
      color: #f37d1a;
      font-size: 35px;
      font-weight: 600;
    }

    h1 {
      width: 50%;
      line-height: 28px;
      font-size: 18px;

      @media (max-width: 1000px) {
        width: 90%;
        font-size: 17px;
        margin-top: 15px;
      }

    }
  }

  .second-div {
    .bottom-col {
      @media (max-width:760px) {
        padding-top: 0 !important;
      }
    }

    .first-card {
      background-color: #66380f;
      border: none;
      border-radius: 15px;
    }

    .second-card {
      background-color: #f37d1a;
      border: none;
      border-radius: 15px;
    }

    .first {
      .i-1 {
        height: 28px;
        width: 28px;
      }

      .content {
        .title {
          font-size: 20px;
        }

        .description {
          font-size: 17px;
          line-height: 25px;
        }

        img {
          height: 340px;
        }
      }
    }
  }

  .row>* {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}