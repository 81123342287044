.performance-main-div {
  background-image: url(../../Assets/Images/discover.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  // margin: 0 3rem;

  @media (max-width:500px) {
    margin: 0 !important;
  }

  .deliver-div {
    background-color: #060a0c;
    opacity: 0.9;
    color: #ffffff;

    .content-div {
      height: 100vh;
      flex-direction: column;

      @media (max-width:1000px) {
        height: 60vh;
      }

      h1 {
        text-align: center;
        font-size: 36px;
        width: 700px;

        font-weight: 700;

        @media (max-width:500px) {
          width: 350px;
          font-size: 23px;
        }
      }

      h6 {
        text-align: center;
        width: 700px;
        font-size: 17px;
        line-height: 30px;
font-weight: 400;
        @media (max-width:500px) {
          width: 350px;
          line-height: 25px;
          font-weight: 400;
          margin-top: 10px;
        }
      }

      .discover-btn {
        height: 50px;
        width: 200px;
        background-color: #f37d1a;
        color: #ffffff;
        border: none;
        border-radius: 5px;
      }
    }
  }
}