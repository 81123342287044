.portfolio-main-div {
  color: #ffffff;
  display: flex;
  flex-direction: column;

  @media (max-width: 1000px) {
    max-width: 100% !important;
  }

  .first-div {
    .title-div {
      h3 {
        font-size: 18px;
        color: #f37d1a;
      }
    }

    .desc {
      line-height: 28px;
      font-size: 18px;
      color: gray;

      @media (max-width: 1000px) {
        font-size: 15px;
      }
    }

    .get-start-btn {
      background-color: #f37d1a;
      height: 50px;
      width: 150px;
      border: none;
      color: #ffffff;
      border-radius: 5px;
      margin-left: 10px;
    }
  }

  .second-div {
    .second-col {
      @media (max-width: 500px) {
        margin-top: 1rem !important;
        padding-top: 0 !important;
      }
    }

    .third-col {
      @media (max-width: 500px) {
        margin-top: 1rem !important;
        padding-top: 0 !important;
      }
    }

    .first-card {
      background-image: url(../../Assets/Images/software.jpg);
      height: 63vh;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 20px;
      border: none;

      @media (max-width: 1000px) {
        height: 40vh;
      }

      @media (max-width: 500px) {
        height: 60vh;
      }
    }

    .second-card {
      background-image: url(../../Assets/Images/WebDevelopment.jpg);
      height: 63vh;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      border-radius: 20px;
      border: none;

      @media (max-width: 1000px) {
        height: 40vh;
      }

      @media (max-width: 500px) {
        height: 60vh;
      }
    }

    .third-card {
      background-image: url(../../Assets/Images/mobile_App_dev.png);
      height: 63vh;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      border-radius: 20px;
      border: none;

      @media (max-width: 1000px) {
        height: 40vh;
      }

      @media (max-width: 500px) {
        height: 60vh;
      }
    }

    .fourth-card {
      background-image: url(../../Assets/Images/digitalMarketing.jpg);
      height: 63vh;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      border-radius: 20px;
      border: none;

      @media (max-width: 1000px) {
        height: 40vh;
      }

      @media (max-width: 500px) {
        height: 60vh;
      }
    }

    .fifth-card {
      background-image: url(../../Assets/Images/cloud-computing.jpg);
      height: 63vh;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      border-radius: 20px;
      border: none;

      @media (max-width: 1000px) {
        height: 40vh;
      }

      @media (max-width: 500px) {
        height: 60vh;
      }
    }

    .sixth-card {
      background-image: url(../../Assets/Images/graphic-design.jpg);
      height: 63vh;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      border-radius: 20px;
      border: none;

      @media (max-width: 1000px) {
        height: 40vh;
      }

      @media (max-width: 500px) {
        height: 60vh;
      }
    }

    .deliver-div {
      background-color: #060a0c;
      opacity: 0.8;
      height: 80vh;
      color: #ffffff;
      border-radius: 20px;

      @media (max-width: 1000px) {
        height: 40vh;
      }

      @media (max-width: 500px) {
        height: 60vh;
      }

      .content-div {
        h1 {
          // text-align: center;
          font-size: 16px;
          color: #f37d1a;
        }

        p {
          // text-align: center;
          font-size: 16px;
          // line-height: 40px;
        }

        .discover-btn {
          height: 40px;
          width: 160px;
          background-color: #f37d1a;
          color: #ffffff;
          border: none;
          border-radius: 5px;

          @media (max-width: 500px) {
            margin-top: 15px !important;
          }
        }
      }
    }
  }
}
